import { useMemo, useState, useCallback, useEffect } from 'react';
import { InputActionMeta, ValueType } from 'react-select';
import { useDebouncedCallback } from 'use-debounce/lib';

import { useCToast } from 'app/koddi-components/src/_ChakraComponents/Toast';
import { formatError } from 'app/koddi-components/src/utils';
import { useOnFirstRender } from 'app/react-ui/hooks/useOnFirstRender.hooks';
import { OptionType, selectValue } from '../../../../Types';
import {
    InputActions,
    PaginatedMultiSelectFieldProps,
    PaginatedSelectFieldHookReturn,
} from '../../SelectField.types';

export const useSelectPaginatedField = <
    T extends selectValue = string | number
>({
    onChange,
    inputValue,
    resetSearchOnSelection,
    fetchOptions,
    trackedPagination,
    dependsOn,
    isMulti,
}: Pick<
    PaginatedMultiSelectFieldProps<T>,
    | 'onChange'
    | 'inputValue'
    | 'resetSearchOnSelection'
    | 'fetchOptions'
    | 'trackedPagination'
    | 'dependsOn'
    | 'isMulti'
>): PaginatedSelectFieldHookReturn => {
    const { showErrorToast } = useCToast();

    const [options, setOptions] = useState<OptionType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const portal = document.getElementById('select-portal');
    useOnFirstRender(() => {
        if (!portal) {
            // eslint-disable-next-line no-console
            console.error(
                'To enable menu portal add  <div id="select-portal"></div> to your DOM'
            );
        }
    });

    const fetchAndSetOptions = useCallback(
        async (count?: number, pageNumber?: number) => {
            if (isLoading || !hasMore) return;

            setIsLoading(true);
            try {
                const data = await fetchOptions(
                    searchValue,
                    pageNumber ?? page,
                    count
                );

                setOptions((prev) => {
                    const newOptions = data.filter(
                        (d) => !prev.find((p) => p.value === d.value)
                    );
                    return [...prev, ...newOptions];
                });

                setHasMore(data.length > 0);
                setPage((prev) => prev + 1);
            } catch (error) {
                showErrorToast(
                    'Error loading paginated options:',
                    formatError(error)
                );
            } finally {
                setIsLoading(false);
            }
        },
        [fetchOptions, hasMore, isLoading, page, searchValue, showErrorToast]
    );

    const handleChange = useCallback(
        (option: ValueType<OptionType>) => {
            if (resetSearchOnSelection) {
                setSearchValue('');
            }

            if (!option) {
                onChange?.(undefined);
                return;
            }

            if (isMulti && Array.isArray(option)) {
                const values = option.map((o) => o.value);
                onChange?.(values as T);
                return;
            }

            onChange?.((option as OptionType<T>).value);
        },
        [onChange, isMulti, resetSearchOnSelection]
    );

    const handleSearch = useCallback(
        async (
            inp: string | undefined
        ): Promise<OptionType<string | number>[] | undefined> => {
            setIsLoading(true);
            try {
                const data = await fetchOptions(inp, 0);
                setOptions(data);
                setHasMore(data.length > 0);
                return data;
            } catch (error) {
                showErrorToast(
                    'Error loading paginated options:',
                    formatError(error)
                );
                return undefined;
            } finally {
                setIsLoading(false);
            }
        },
        [fetchOptions, showErrorToast]
    );

    useEffect(() => {
        const loadAllPages = async () => {
            if (trackedPagination && !initialized && inputValue) {
                setInitialized(true);
                setIsLoading(true);

                try {
                    const data = await fetchOptions(
                        '',
                        0,
                        trackedPagination.count
                    );

                    setOptions(data);
                    setPage(trackedPagination.page);
                    setHasMore(true);
                } catch (error) {
                    showErrorToast(
                        'Error loading paginated options:',
                        formatError(error)
                    );
                } finally {
                    setIsLoading(false);
                }
            }
        };

        loadAllPages();
    }, [
        trackedPagination,
        dependsOn,
        inputValue,
        fetchOptions,
        showErrorToast,
        initialized,
    ]);

    const handleMenuOpen = useCallback(async () => {
        setOpen(true);
        if (options.length === 0 && !initialized) {
            await fetchAndSetOptions();
        }
    }, [options.length, initialized, fetchAndSetOptions]);

    const valueProp = useMemo(() => {
        if (isMulti && Array.isArray(inputValue)) {
            return {
                value:
                    inputValue
                        ?.map((value) => {
                            const matchedOption = options?.find(
                                (option) => option.value === value
                            );
                            return matchedOption ?? null;
                        })
                        .filter((option) => option) ?? [],
            };
        }
        return {
            value:
                options?.find((option) => inputValue === option.value) ?? null,
        };
    }, [options, inputValue, isMulti]);

    const handleSearchChange = async (
        value: string,
        { action }: InputActionMeta
    ): Promise<OptionType<string | number>[] | undefined> => {
        switch (action) {
            case InputActions.setValue:
                return;
            case InputActions.inputBlur:
            case InputActions.menuClose:
                setSearchValue('');
                return;
            case InputActions.inputChange:
                setSearchValue(value);
                // eslint-disable-next-line consistent-return
                return handleSearch(value);
            default:
                break;
        }
    };

    const debouncedChange = useDebouncedCallback(handleChange, 100);

    return {
        portal,
        open,
        setOpen,
        valueProp: valueProp as {
            value?: OptionType<string | number> | undefined;
        },
        searchValue,
        options,
        isLoading,
        handleSearchChange,
        handleMenuOpen,
        debouncedChange,
        fetchAndSetOptions,
    };
};
