import { Center, Text } from '@chakra-ui/react';
import React from 'react';
import { Colors, FontSizes } from '../ChakraTheme';

interface NoDataProps {
    message?: string;
    height?: string | number;
}

export const CNoData = ({
    message = 'No data available',
    height = '200px',
}: NoDataProps): JSX.Element => {
    return (
        <Center height={height} width="100%">
            <Text color={Colors.gray} fontSize={FontSizes.md}>
                {message}
            </Text>
        </Center>
    );
};
